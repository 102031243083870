import '../src/polyfills.js'
import { createApp, App, ref } from 'vue'
import NrEditor from '@nextrequestco/components/src/components/NrEditor/nr-editor.vue'
import '../styles/nr_editor.scss'

function initVueEditor(selectors?: string): App[] {
  return Array.from(
    document.querySelectorAll<HTMLTextAreaElement>(selectors || '.js-nr-editor')
  )
    .map((el) => {
      // @ts-ignore
      if ($(el).data('vue')) {
        return undefined
      }

      const editorEl: HTMLDivElement = document.createElement('div')
      const minHeight = el.getAttribute('rows')
      const placeholder = el.getAttribute('placeholder') || ''
      const resizable = el.getAttribute('resizable') !== 'true'
      const toolbarPrefs = (el.dataset.editorToolbars || '')
        .split(',')
        .reduce((prefs: { [key: string]: any }, pref: string) => {
          if (!pref) {
            return prefs
          }

          prefs[pref] = true
          return prefs
        }, {})
      el.style.display = 'none'
      el.setAttribute('data-vue-editor', 'true')

      // @ts-ignore
      $(el).after($(editorEl))

      const content = ref(el.value)
      // Methods that can be called by code outside the Vue app.
      const publicMethods = {
        empty() {
          content.value = ''
        },
        setContent(newContent) {
          content.value = newContent
        }
      }
      // @ts-ignore
      $(el).data('vue', publicMethods)

      const vueEditor = createApp({
        components: { 'nr-editor': NrEditor },
        template: `
          <nr-editor
            @update:modelValue="updateTextarea"
            v-bind="toolbarPrefs"
            :rows="${minHeight}"
            placeholder="${placeholder}"
            :resizable="${resizable}"
            v-model="content"
            data-id="${el.id}"
          />
        `,
        data() {
          return {
            content: content,
            toolbarPrefs
          }
        },
        methods: {
          updateTextarea: function (text: string) {
            el.value = text
            this.$emit('input', text)
          }
        }
      })

      vueEditor.mount(editorEl)

      el.addEventListener('input', () => {
        publicMethods.setContent(el.value)
      })

      return vueEditor
    })
    .filter((editor) => !!editor)
}

declare global {
  interface Window {
    initVueEditor: typeof initVueEditor
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.initVueEditor = initVueEditor
  window.initVueEditor()
})
